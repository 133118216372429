<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <Input
            v-model="search.search_data"
            style="max-width: 350px"
            class="d-inline-block mb-2"
            placeholder="Search by title..."
            type="text"
        />
      </div>
      <div class="col-md-6 text-right">
        <div class="d-flex justify-content-end">
          <div
              class="form-group d-flex align-items-center justify-content-start mb-md-0"
          >
            <label class="mr-1">Show </label>
            <Select
                style="margin-right: 10px"
                @on-change="paginateChange"
                v-model="search.paginate"
                :clearable="false"
            >
            <Option :value="10"> 10</Option>
            <Option :value="30"> 30</Option>
            <Option :value="50"> 50</Option>
            <Option :value="80"> 80</Option>
            <Option :value="100"> 100</Option>
            </Select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-row class="justify-content-between">
            <b-col cols="6" lg="3">
              <div class="form-group">
                <Select
                    v-model="search.salary_filter_type"
                    @on-change="salaryFilter"
                    :clearable="true"
                    placeholder="Salary Filter Type"
                >
                <Option :value="1"> Max</Option>
                <Option :value="2"> Min</Option>
                </Select>
              </div>
            </b-col>
            <b-col cols="6" lg="3">
              <div class="form-group">
                <Input
                    v-model="search.salary_from"
                    class="d-inline-block mr-1"
                    placeholder="From Salary"
                    type="number"
                    :disabled="!search.salary_filter_type"
                />
              </div>
            </b-col>
            <b-col cols="6" lg="3">
              <div class="form-group">
                <Input
                    v-model="search.salary_to"
                    class="d-inline-block mr-1"
                    placeholder="To Salary"
                    type="number"
                    :disabled="!search.salary_filter_type"
                />
              </div>
            </b-col>
            <b-col cols="6" lg="3">
              <div class="form-group">
                <Select
                    v-model="search.skill_filter_type"
                    @on-change="skillFilter"
                    :clearable="true"
                    placeholder="Skill Filter Type"
                >
                <Option :value="1"> AND</Option>
                <Option :value="2"> OR</Option>
                </Select>
              </div>
            </b-col>
            <b-col cols="6" lg="2">
              <div class="form-group">
                <Select
                    :disabled="!search.skill_filter_type"
                    v-model="search.skill_id"
                    @on-change="SearchData"
                    filterable
                    multiple
                    :clearable="true"
                    placeholder="Select Skill"
                >
                <Option
                    v-for="(skill, index) in skills"
                    :key="index"
                    :value="skill.id"
                    v-if="skills.length"
                >{{ skill.name }}
                </Option>
                </Select>
              </div>
            </b-col>
            <b-col cols="6" lg="2">
              <div class="form-group">
                <Select
                    v-model="search.commitment"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select Work
               Availability"
                >
                <Option value="UNAVAILABLE"> UNAVAILABLE</Option>
                <Option value="FULLTIME"> FULLTIME</Option>
                <Option value="PARTTIME"> PARTTIME</Option>
                <Option value="HOURLY"> HOURLY</Option>
                <Option value="CONTRACTUAL"> CONTRACTUAL</Option>
                </Select>
              </div>
            </b-col>
            <b-col cols="6" lg="2">
              <div class="form-group">
                <Select
                    v-model="search.seniority"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select seniority"
                >
                <Option value="ENTRY"> ENTRY</Option>
                <Option value="MID"> MID</Option>
                <Option value="SENIOR"> SENIOR</Option>
                <Option value="MANAGER"> MANAGER</Option>
                </Select>
              </div>
            </b-col>
            <b-col cols="6" lg="2">
              <div class="form-group">
                <Select
                    v-model="search.workplace_type"
                    @on-change="SearchData"
                    :clearable="true"
                    placeholder="Select Work Place Type"
                >
                <Option value="REMOTE"> REMOTE</Option>
                <Option value="ONSITE"> ONSITE</Option>
                <Option value="HYBRID"> HYBRID</Option>
                </Select>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div class="form-group">
                <DatePicker
                    v-model="deadline_from_date"
                    type="date"
                    placeholder="Job deadline From date"
                    style="width: 100%"
                >
                </DatePicker>
              </div>
            </b-col>
            <b-col cols="12" lg="2">
              <div class="form-group">
                <DatePicker
                    v-model="deadline_to_date"
                    type="date"
                    placeholder="Job deadline to date"
                    style="width: 100%"
                >
                </DatePicker>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <div class="row">
          <div class="col-xl-12 m-auto">
            <div
                class="card jobs mt-1"
                v-for="(job, index) in JobData.data"
                v-if="JobData.data.length > 0"
                :key="index"
            >
              <router-link :to="'/job/' + job.id">
                <div class="card-body" style="border-radius: 25px">
                  <b-row>
                    <b-col
                        cols="12"
                        lg="12"
                        class="d-flex justify-lg-content-between flex-column flex-lg-row"
                    >
                      <div class="order-1 order-lg-0">
                        <h5
                            class="card-title mb-1 font-weight-bolder font-weight-bold"
                        >
                          {{ job.title }}
                        </h5>
                        {{ job.company_name }}
                      </div>
                      <div class="order-0 order-lg-1 ml-auto">
                        <img
                            :src="
                            job.company_logo == null ? '' : job.company_logo
                          "
                            height="70"
                        />
                      </div>
                    </b-col>
                    <b-col cols="12 mb-1" style="margin-top: 25px">
                      <h6 class="card-subtitle text-secondary">
                        <b>Vacancy</b>: {{ job.vacancy }}
                      </h6>
                    </b-col>
                    <b-col cols="12 mb-1">
                      <h6 class="card-subtitle text-secondary">
                        <b>Job type</b>: {{ job.commitment }}
                      </h6>
                    </b-col>
                    <b-col cols="12">
                      <div class="d-flex justify-content-between">
                        <div class="div">
                          <h6 class="card-subtitle text-secondary">
                            <b>Experience</b>:
                                             {{ job.required_experience }} Years
                          </h6>
                          <h6
                              class="card-subtitle text-secondary"
                              v-if="job.district"
                              style="margin-top: 5px"
                          >
                            <b>Location</b>: {{ job.district }} ,
                                           {{ job.division }}
                          </h6>
                        </div>
                        <h6 class="card-subtitle text-secondary">
                          <b>Deadline:</b> {{ job.deadline }}
                        </h6>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </router-link>
            </div>

            <div v-else class="text-center">
              <h4>No data found!</h4>
            </div>
          </div>
        </div>
        <b-card-body>
          <div class="d-flex justify-content-between align-items-center">
            <span class="text-muted"
            >Showing {{ JobData.meta.from }} to {{ JobData.meta.to }} of
              {{ JobData.meta.total }} entries</span
            >
            <pagination
                :data="JobData"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
            ></pagination>
          </div>
        </b-card-body>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BCardTitle,
  BTabs,
  BTab,
  BCardText,
} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      search: {
        search_data: "",
        commitment: "",
        seniority: "",
        workplace_type: "",
        salary_from: "",
        salary_to: "",
        salary_filter_type: "",
        skill_filter_type: "",
        skill_id: [],
        deadline_from_date: "",
        deadline_to_date: "",
        paginate: 10,
        page: 1,
        status: "",
      },
      deadline_from_date: "",
      deadline_to_date: "",
      skills: [],
      companyData: [],
      JobData: [],
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    BCardTitle,
    BCardText,
    BTabs,
    BTab,
  },
  mounted() {
    this.getResults();
    this.getSkills();
  },
  methods: {
    formatDate(date) {
      if (date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
      return null;
    },

    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      this.search.from_date = this.from_date
          ? this.formatDate(this.from_date)
          : "";
      this.search.to_date = this.from_date ? this.formatDate(this.to_date) : "";
      this.search.estimated_from_date = this.estimated_from_date
          ? this.formatDate(this.estimated_from_date)
          : "";
      this.search.estimated_to_date = this.estimated_to_date
          ? this.formatDate(this.estimated_to_date)
          : "";
      this.search.deadline_from_date = this.deadline_from_date
          ? this.formatDate(this.deadline_from_date)
          : "";
      this.search.deadline_to_date = this.deadline_to_date
          ? this.formatDate(this.deadline_to_date)
          : "";

      const queryParams = this.buildQueryParamString(this.search);
      axios
          .get(`/candidate/get/company/job${queryParams}`)
          .then((res) => {
            this.JobData = res.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    getSkills() {
      axios
          .get("/candidate/skills")
          .then((res) => {
            this.skills = res.data.skills;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    Filtering() {
      this.filter_status = !this.filter_status;
    },

    salaryFilter() {
      if (
          this.search.salary_filter_type == null ||
          this.search.salary_filter_type == ""
      ) {
        this.search.salary_from = "";
        this.search.salary_to = "";
      }
      if (this.search.salary_from.length || this.search.salary_to.length) {
        this.SearchData();
      }
    },

    skillFilter() {
      if (
          this.search.skill_filter_type == null ||
          this.search.skill_filter_type == ""
      ) {
        this.search.skill_id = [];
      }
      if (this.search.skill_id.length) {
        this.SearchData();
      }
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },

  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.required_experience_from": function (val) {
      this.SearchData();
    },
    "search.required_experience_to": function (val) {
      this.SearchData();
    },
    "search.salary_from": function (val) {
      this.SearchData();
    },
    "search.salary_to": function (val) {
      this.SearchData();
    },
    deadline_from_date: function (val) {
      this.SearchData();
    },
    deadline_to_date: function (val) {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
<style scoped>
td p {
  margin-bottom: 0;
}

.jobs {
  transition: all linear 0.3s;
  cursor: pointer;
}

.jobs:hover {
  background-color: #f5f5f5;
}
</style>
